
.content{
    height: 100%;
    width:100%;
    background-color:#F5F5F6;
    padding-bottom: 24px;
    padding-top:64px;
};

.bold{
    font-weight: 900;
}
.right{
    float:right;
}
.tableContainer{
    background:#fff;
    padding:30px 10px;
    font-family: 'Roboto', sans-serif;
}
.justify{
    text-align: justify;
}
.spacer{
    flex: 1 1 100%;
}
.tableRowTitle{
    width:150px;
}
.table{
    font-size:15px;
}
.submitContainer{
    margin: 0 auto;
    display: table;
}
.main {
    margin: 0 auto;
    display: table;
}
.paper {
    margin: 0 auto;
    display: table;
    max-width: 300px;
    text-align: center;
    padding:20px;
    margin-top:40px;
}
.hide{
    display:none;
}
.button{
    margin-top:20;
    margin-bottom:20;
}
.icon{
    margin-right:10;
}
.logo{
    width:200px;
    margin-bottom:20px;
}
.form {
    width: 100%;
    margin-top: 8px;
}
.submit{
    margin-top: 24px !important;
    margin-bottom:24px !important;
}
.submitGreen{
    margin-top: 24px !important;
    margin-bottom:24px !important;
    background-color: #4caf50 !important;
    color: #fff ;
}
.submitRed{
    margin-top: 24px !important;
    margin-bottom:24px !important;
    background-color: #db4a4a !important;
    color: #fff ;
}
.progress {
    margin: 0 auto;
    display: table !important;
    text-align: center;
    margin-top: 24px;
}
.input{
    margin-top: 8px;
}
.toolbar{
    padding-right: 24px;
}
.appBar{
    z-index: 100
    
}
.logoAppBar{
    width:100px;
    margin-right:10px;
}
.appBarShift{
    margin-left: 240px;
    width: (100%)-240px;
}
.tableCellNumber{
    width: 40px;
    padding-left: 5px;
    padding-right: 5px;
}
.tableRowSlider{
    height: 100px;
    min-width: 400px;
}
.menuButton{
    margin-left: 12px;
    margin-right: 0px;
}
.menuButtonHidden{
    display: none;
}
.title{
    flex-grow: 1
}
.link{
    text-decoration:none
}
.nested {
    padding-left: 32px
}
.drawerPaper {
    position: relative;
    white-space: nowrap;
    width: 240px;
    display:block
  };
.drawerPaperCloseCustom{
    overflow-x: hidden;
    width:0;
}
.toolbarIcon {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 8px;
}
.drawerPaperClose {
    overflow-x: hidden;
    width: 56px;
}
.muyBajo{
    width: 40px;
    padding-left: 5px;
    padding-right: 5px;
    font-family: 'Poppins';
}
.bajo{
    width: 40px;
    padding-left: 5px;
    padding-right: 5px;
    font-family: 'Poppins';
}
.medioBajo{
    width: 40px;
    padding-left: 5px;
    padding-right: 5px;
    font-family: 'Poppins';
}
.promedio{
    width: 40px;
    padding-left: 5px;
    padding-right: 5px;
    font-family: 'Poppins';
}
.medioAlto{
    width: 40px;
    padding-left: 5px;
    padding-right: 5px;
    font-family: 'Poppins';
}
.alto{
    width: 40px;
    padding-left: 5px;
    padding-right: 5px;
    font-family: 'Poppins';
}
.muyAlto{
    width: 40px;
    padding-left: 5px;
    padding-right: 5px;
    font-family: 'Poppins';
}
.defaultScore{
    width: 40px;
    padding-left: 5px;
    padding-right: 5px;
    font-family: 'Poppins';
}
.cellCustom {
    text-align: center;
    vertical-align: middle;
}

#overlay {
    position: fixed; /* Sit on top of the page content */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.3); /* Black background with opacity */
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
}
.colorBall7 {
    overflow: visible; 
    width: 20px;
    height: 20px;
    border-radius: 20px; 
    padding: 5px;
    background-color: #63BE7B !important;
}
.colorBall6 {
    overflow: visible; 
    width: 20px;
    height: 20px;
    border-radius: 20px; 
    padding: 5px;
    background-color: #B1D580 !important;
}
.colorBall5 {
    overflow: visible; 
    width: 20px;
    height: 20px;
    border-radius: 20px; 
    padding: 5px;
    background-color: #DDE182 !important;
}
.colorBall4 {
    overflow: visible; 
    width: 20px;
    height: 20px;
    border-radius: 20px; 
    padding: 5px;
    background-color: #FEE382 !important;
}
.colorBall3 {
    overflow: visible; 
    width: 20px;
    height: 20px;
    border-radius: 20px; 
    padding: 5px;
    background-color: #FCBF7B !important;
}
.colorBall2 {
    overflow: visible; 
    width: 20px;
    height: 20px;
    border-radius: 20px; 
    padding: 5px;
    background-color: #FA9B74 !important;
}
.colorBall1 {
    overflow: visible; 
    width: 20px;
    height: 20px;
    border-radius: 20px; 
    padding: 5px;
    background-color: #F8696B !important;
}

@media print {
    .colorBall7 {
        background-color: #63BE7B !important;
        -webkit-print-color-adjust: exact; 
    }
    .colorBall6 {
        background-color: #B1D580 !important;
        -webkit-print-color-adjust: exact; 
    }
    .colorBall5 {
        background-color: #DDE182 !important;
        -webkit-print-color-adjust: exact; 
    }
    .colorBall4 {
        background-color: #FEE382 !important;
        -webkit-print-color-adjust: exact; 
    }
    .colorBall3 {
        background-color: #FCBF7B !important;
        -webkit-print-color-adjust: exact; 
    }
    .colorBall2 {
        background-color: #FA9B74 !important;
        -webkit-print-color-adjust: exact; 
    }
    .colorBall1 {
        background-color: #F8696B !important;
        -webkit-print-color-adjust: exact; 
    }
}

